.popup-items, .popup-sections {
    border-radius: 5px;
}

.popup-items::-webkit-scrollbar, .popup-sections::-webkit-scrollbar {
    width: 10px;
    background-color: rgba(0, 0, 0, 0.08);
    border-radius: 5px;
}

.popup-items::-webkit-scrollbar-thumb, .popup-sections::-webkit-scrollbar-thumb {
    background-color: #704dfb;
    border-radius: 5px;
}

.items, .sections {
    padding: 5px;
}

.item, .section {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all .1s linear;
    scrollbar-color: rgb(218, 196, 248) !important;
}

.item:hover, .item:active, .section:hover, .section:active {
    background-color: #dfd6ff;
}

.item p, .section p {
    margin-bottom: 0;
    margin-left: 5px;
    align-self: center;
    font-size: 14px;
    text-align: start;
    padding-right: 10px;
}